<template>
  <div>
    <v-btn
      flat
      small
      block
      v-if="more_messages"
      class="text-capitalize caption"
      :loading="loading"
      :disabled="loading"
      @click="loadMore"
    >
      {{ $t('see_more') }}
    </v-btn>
    <div
      v-show="getPermissions([`chat.view_message`]) || isAdmin"
      class="message"
      v-for="(message, index) in messages"
      :key="index"
      :class="message.user == username ? 'own' : 'other'"
    >
      <div
        class="username"
        v-if="index > 0 && messages[index - 1].user != message.user"
      >
        {{ message.user }}
      </div>
      <div class="username" v-if="index == 0">{{ message.user }}</div>
      <!-- <div style="margin-top: 5px; border: 1px solid"></div> -->
      <div class="content">
        <div v-html="message.content"></div>
      </div>
      <div v-if="message.attachment">
        <span v-for="(attch, index) in message.attachment" :key="index">
          <div class="content">
            <a :href="attch.attachment" target="_blank">
              <v-img
                v-if="isImage(attch)"
                :src="attch.attachment"
                width="120"
              />
              <div v-else v-html="attch.name"></div>
            </a>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'messages',
  data() {
    return {
      messages: [],
      username: '',
      loader: null,
      loading: false,
      more_messages: false,
      current_chat: 0
    }
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin',
      me: 'session/me'
    })
  },
  methods: {
    loadMessages(chat_pk) {
      this.username = this.me.username
      this.current_chat = chat_pk
      this.messages = []
      this.$api.chat.product
        .list({
          opt: {
            params: {
              chat: chat_pk
            }
          }
        })
        .then((response) => {
          this.next = response.data.next
          this.checkMsg(this.next)
          let results = response.data.results.reverse()
          for (let message of results) {
            this.messages.push({
              user: message.username,
              content: message.comment,
              attachment: message.message_attch
            })
          }
        })
    },
    appendMessage(message) {
      this.messages.push({
        user: message.username,
        content: message.comment,
        attachment: message.message_attch
      })
    },
    loadMore() {
      axios
        .get(this.next, {
          params: {
            chat: this.current_chat
          }
        })
        .then((response) => {
          this.next = response.data.next
          this.checkMsg(this.next)
          let results = response.data.results.reverse()
          for (let message of results) {
            this.messages.unshift({
              user: message.username,
              content: message.comment,
              attachment: message.message_attch
            })
          }
        })
    },
    checkMsg(next) {
      if (next) {
        this.more_messages = true
      } else {
        this.more_messages = false
      }
    },
    isImage(image) {
      const spliced = image.name.split('.')
      const format = spliced[spliced.length - 1]
      return ['png', 'jpg', 'jpeg'].includes(format)
    }
  }
  /*watch: {
    loader () {
      const l = this.loader
      this[l] = !this[l]
      setTimeout(() => (this[l] = false), 3000)
      this.loader = null
    }
  }*/
}
</script>
