<template>
  <v-dialog v-model="user_list" width="500">
    <!-- New Chat -->
    <v-card class="new-chat">
      <v-card-title class="headline text-xs-center primary iprint-fifth--text">
        {{ $t('start_user_chat') }}
        <!-- <v-btn icon dark @click="user_list = false" absolute right>
        <v-icon>fa-times</v-icon>
        </v-btn>-->
      </v-card-title>
      <v-card-text>
        <v-col xs="12" layout justify="center">
          <v-btn block color="white" v-if="load_more" @click="loadMore">{{
            $t('see_more')
          }}</v-btn>
        </v-col>
        <!-- select user to chat -->
        <v-list>
          <!-- Parte comentada por @david17art, por que me daba error, antes estaba v-bind:key="user.id" -->
          <v-list-item
            v-show="getPermissions([`chat.view_chatuser`]) || isAdmin"
            v-for="(user, index) in users"
            v-bind:key="index"
            @click="selectChat(user.id)"
            :class="{
              user: true,
              'select-user': user.id == select_pk ? true : false
            }"
          >
            <v-list-item-avatar>
              <img src="https://cdn.vuetifyjs.com/images/john.jpg" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ user.username }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <!-- End - select user to chat -->
      </v-card-text>
      <v-card-actions>
        <v-col xs="12" layout justify="center">
          <v-btn color="success" @click="createChat">{{ $t('confirm') }}</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
    <!-- End - New Chat -->
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'user-chat-list',
  data() {
    return {
      users: [],
      select_pk: '',
      total: 0,
      next: 0,
      user_id: '',
      load_more: false,
      user_list: false
    }
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin',
      me: 'session/me'
    })
  },
  methods: {
    closeModal() {
      this.user_list = false
    },
    selectChat(pk) {
      this.select_pk = pk
    },
    createChat() {
      if (this.select_pk != '') {
        this.user_id = this.me.id
        let data = {
          chat_type: 'IND',
          chat_user_chat: [{ user: this.user_id }, { user: this.select_pk }]
        }
        this.$api.chat
          .create({
            form: data
          })
          .then((response) => {
            if (response.status == 201 && response.statusText == 'Created') {
              //let text = `${this.$tc("customer",1)} ${this.$tc("created",1)}`
              this.$emit('compĺeted', true)
            }
          })
          .catch((error) => {
            this.$store.dispatch('alert', {
              type: 'error',
              text: error.response.data.non_field_errors[0]
            })
          })
      }
    },
    loadUsers() {
      this.users = []
      this.userService()
      this.user_list = true
    },
    loadMore() {
      this.userService(this.next)
    },
    userService(url) {
      this.$api.user.list({}).then((response) => {
        this.total = response.data.count
        this.next = response.data.next
        if (this.next) {
          this.load_more = true
        } else {
          this.load_more = false
        }
        for (let user of response.data.results) {
          if (user.id != this.user_id) {
            this.users.push(user)
          }
        }
      })
    }
  }
}
</script>
