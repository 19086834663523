<template>
  <!-- <v-container> -->
  <v-row row>
    <v-col
      xs="12"
      :class="{
        'sm12 md12': drawer_chat,
        'sm8 md10': !drawer_chat
      }"
      order="2"
      style="position: relative"
    >
      <div class="chat-container" ref="chatContainer">
        <messages-chat ref="messagesChat" />
      </div>
      <v-col md="6" sm="12" v-for="(attch, index) in chat_files" :key="index">
        <div class="text-xs-center">
          <v-chip close @click:close="removeAttch(index)">
            {{ attch.name }}
          </v-chip>
        </div>
      </v-col>
      <div class="typer">
        <input
          type="text"
          :placeholder="$t('write_a_message') + '...'"
          v-model="content"
          :disabled="disabled"
        />
        <input
          type="file"
          style="display: none"
          ref="image"
          multiple="true"
          @change="onFilePicked"
        />
        <v-btn icon color="grey darken-1" absolute @click.native="imageClick">
          <v-icon :medium="!$vuetify.breakpoint.xsOnly">fa-paperclip</v-icon>
        </v-btn>
        <v-btn icon color="grey darken-1" right absolute @click="sendMessage">
          <v-icon :medium="!$vuetify.breakpoint.xsOnly">fa-paper-plane</v-icon>
        </v-btn>
      </div>
    </v-col>
    <!-- Chat contacts -->
    <v-col
      sm="4"
      md="3"
      order="1"
      class="scrollable mx-2"
      v-if="drawer_chat || !$vuetify.breakpoint.xsOnly"
    >
      <v-list subheader>
        <v-list-item
          v-show="getPermissions([`chat.add_chat`]) || isAdmin"
          @click="newChat"
        >
          <v-list-item-content>
            <v-list-item-title>{{ $t('new_chat') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-subheader>{{ $t('recent_chats') }}</v-subheader>
      </v-list>
      <v-list subheader>
        <!-- Web -->
        <div v-if="!$vuetify.breakpoint.xsOnly">
          <v-list
            v-for="(chat, index) in chats"
            v-bind:key="chat.name + index"
            avatar
            style="display: flex"
          >
            <v-list-item
              style="width: 100%"
              :class="{
                'select-user': active_chat == chat.pk
              }"
              @click="loadMessages(chat.pk), markAsRead(chat.pk)"
            >
              <v-list-item-avatar>
                <img src="https://cdn.vuetifyjs.com/images/john.jpg" />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ chat.name }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-badge overlap>
                  <template v-slot:badge>
                    <span>{{ chat.not_read }}</span>
                  </template>
                  <v-btn icon ripple small>
                    <v-icon color="grey lighten-1">fa-comment</v-icon>
                  </v-btn>
                </v-badge>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>
        <!-- Movil -->
        <div v-else>
          <v-list
            v-for="(chat, index) in chats"
            v-bind:key="chat.name + index"
            avatar
            style="display: flex"
          >
            <v-list-item
              style="width: 100%"
              :class="{
                'select-user': active_chat == chat.pk
              }"
              @click="loadMessages(chat.pk), markAsRead(chat.pk)"
            >
              <v-list-item-avatar>
                <img src="https://cdn.vuetifyjs.com/images/john.jpg" />
              </v-list-item-avatar>
              <v-list-item-content style="overflow: inherit !important">
                <v-badge overlap>
                  <template v-slot:badge>
                    <span>{{ chat.not_read }}</span>
                  </template>
                  <v-list-item-title style="max-width: 70px">
                    {{ chat.name }}
                  </v-list-item-title>
                </v-badge>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-list>
    </v-col>
    <!-- End - Chat contacts -->

    <!-- Hide or show the user's chat panel on the mobile -->
    <div v-if="$vuetify.breakpoint.xsOnly">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            color="indigo"
            dark
            bottom
            right
            fab
            fixed
            @click="drawer_chat = !drawer_chat"
            small
            class="mb-5"
          >
            <v-icon v-on="on">fa-user</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('user_panel') }}</span>
      </v-tooltip>
    </div>
    <v-snackbar
      v-model="alert.active"
      :dismissible="true"
      :color="alert.type"
      right
      :timeout="6000"
      >{{ alert.text }}</v-snackbar
    >
    <!-- <v-dialog v-model="user_list" width="500"  > -->
    <user-chat-list ref="user_modal" @compĺeted="selected" />
    <!-- </v-dialog> -->
    <!-- End - Hide or show the user's chat panel on the mobile -->
  </v-row>
  <!-- </v-container> -->
</template>
<script>
import { mapGetters } from 'vuex'
import './Chat.css'
import messagesChat from './Messages.vue'
import userChatList from './UserChatList.vue'

export default {
  components: {
    messagesChat,
    userChatList
  },
  data() {
    return {
      content: '',
      chats: [],
      drawer_chat: false,
      username: '',
      user_id: '',
      alert: {
        active: false,
        type: 'info',
        text: ''
      },
      active_chat: null,
      disabled: true,
      user_list: false,
      chat_files: [],
      loading: false
    }
  },
  methods: {
    sendMessage(message) {
      if (this.content == '') {
        this.alert.active = true
        this.alert.text = this.$t('empty_message')
      } else {
        let chat = null
        if (this.chat_files.length == 0) {
          chat = {
            chat: this.active_chat,
            user: this.user_id,
            comment: this.content
          }
        } else {
          chat = new FormData()
          chat.append('chat', this.active_chat)
          chat.append('user', this.user_id)
          chat.append('comment', this.content)
          for (let attch of this.chat_files) {
            chat.append('message_attch', attch)
          }
        }
        this.$api.chat.product
          .create({
            form: chat
          })
          .then((response) => {
            if (response.status == 201 && response.statusText == 'Created') {
              this.chat_files = []
              let text = `${this.$t('message')} ${this.$tc('send', 2)}`
              this.alert.active = true
              this.alert.text = text
              this.content = ''
              this.$store.commit('socketMessage', {
                type: 'chat',
                content: response.data
              })
            }
          })
          .catch((error) => {
            console.error(error.response)
          })
      }
    },
    loadChats() {
      this.chats = []
      this.username = this.me.username
      this.user_id = this.me.id
      let global_this = this
      this.$api.chat
        .list({
          opt: {
            params: {
              size: this.perPage,
              chat_user_chat__user_id: this.user_id
            }
          }
        })
        .then((response) => {
          for (let chat of response.data.results) {
            for (let item of chat.chat_user_chat) {
              if (item.username != global_this.username) {
                global_this.chats.push({
                  name: item.username,
                  pk: chat.pk,
                  not_read: item.not_read
                })
              }
            }
          }
        })
        .finally(() => {
          if (this.$store.getters.getIsloading) {
            this.$store.dispatch('actionLoading')
          }
          this.loading = false
        })
    },
    loadMessages(pk) {
      this.active_chat = pk
      this.disabled = false
      this.$refs.messagesChat.loadMessages(pk)
    },
    checkMessage(message) {
      if (message.type == 'chat') {
        if (message.content.chat == this.active_chat) {
          this.$refs.messagesChat.appendMessage(message.content)
        }
      }
    },
    markAsRead(pk) {
      this.$api.chat.product
        .markRead({
          pk: pk
        })
        .then((response) => {
          if (response.status == 200) {
            this.loadChats()
          }
        })
    },
    newChat() {
      this.$refs.user_modal.loadUsers()
      // this.user_list = true
    },
    selected() {
      // this.user_list = false
      this.$refs.user_modal.closeModal()
      let text = `${this.$t('chat')} ${this.$tc('created', 1)}`
      this.$store.dispatch('alert', {
        type: 'success',
        text: text
      })
      this.loadChats()
    },
    imageClick() {
      this.$refs.image.click()
    },
    onFilePicked(e) {
      this.chat_files = []
      for (let item of e.target.files) {
        this.chat_files.push(item)
      }
    },
    removeAttch(index) {
      this.chat_files.splice(index, 1)
    }
  },
  mounted() {
    this.loadChats()
    this.$store.subscribe((mutation, state) => {
      if (mutation.type == 'SOCKET_ONMESSAGE') {
        this.checkMessage(mutation.payload)
      }
    })
  },
  computed: {
    conected() {
      return this.$store.getSocketStatus.isConnected
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin',
      me: 'session/me'
    })
  }
}
</script>
